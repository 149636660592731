<template>
  <div class="card p-5"><h4>Veuillez sélectionner un élement de menu.</h4></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import mAxiosApi from "@/api";


import * as moment from "moment";

export default defineComponent({
  name: "empty",
  components: { },
 
  setup() {
    moment.default.locale("fr");

    return {}
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}

</script>